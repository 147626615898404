<template>
    <front-layout>
        <v-container fluid style="min-height: calc(100vh - 228px)">
            <v-container v-if="cities.length > 0">
                <v-row>
                    <v-col md="9" class="pa-0">
                        <h1 class="city__h1">{{ $tc('headers.projects_on_map') }}</h1>
                        <div class="city__card" v-for="item in cities" :key="item.id">
                            <div class="city__first-row">
                                <a class="city__name text-decoration-none" :href="`city/${item.slug}`">{{ item.name
                                }}</a>
                                <router-link :to="{name: 'cities.show', hash: '#developers', params: {city: item.slug}}"
                                    class="city__qty-dev text-decoration-none">
                                    {{ $tc('entities.developer', item.developers_count) }}
                                </router-link>
                                <router-link :to="{name: 'cities.show', hash: '#projects', params: {city: item.slug}}"
                                    class="city__qty-residential-complexes text-decoration-none">
                                    <span>{{ ($tc('entities.project', item.projects_count)) }}</span>
                                </router-link>
                            </div>
                            <div class="city__second-row">
                                <div class="city__img">
                                    <img v-if="item.image_url" :src="`${item.image_url}`" alt="City photo">
                                </div>
                                <open-street-map-multi-marker v-if="project[item.id] && project[item.id].latitude"
                                    class="city__map" :height="$vuetify.breakpoint.width > 900 ? 219 : 141"
                                    :lat="project[item.id].latitude" :lng="project[item.id].longitude"
                                    :markers="project" :zoom="17" :id="'project-map'+item.id" />
                            </div>
                        </div>
                    </v-col>

                    <v-col md="3" class="pt-0 pr-0 pl-0 pl-md-4">
                        <div v-if="$vuetify.breakpoint.width > 900" class="mt-6 mt-md-0">
                            <aside-link-variable-card />
                        </div>

                        <advertising :limit="5" />
                    </v-col>
                </v-row>
            </v-container>
        </v-container>
    </front-layout>
</template>

<script>
import FrontLayout from "../../../layouts/FrontLayout";
import City from "@/models/City";
import AsideLinkVariableCard from "@/components/cards/AsideLinkVariableCard";
import Project from "@/models/Project";
import OpenStreetMapMultiMarker from "@/components/OpenStreetMapMultiMarker";

export default {
    name: 'city.index',
    components: {
        FrontLayout,
        AsideLinkVariableCard,
        OpenStreetMapMultiMarker,
        Advertising: () => import('@/components/ads/Advertising'),
    },
    data: function () {
        return {
            cities: [],
            ads: [],
            ads_desktop: [],
            project: []
        }
    },
    metaInfo() {
        return {
            title: this.$t('meta.cities_title'),
            meta: [
                { name: 'description', content: this.$t('meta.cities_description') }
            ]
        }
    },
    methods: {
        async init() {
            this.isLoading = true

            this.cities = await City.get()
            this.project = await Project.get()

            this.isLoading = false
        },
        chunkArray(arr, qty) {
            return arr.reduce((prev, cur, i, a) => !(i % qty) ? prev.concat([a.slice(i, i + qty)]) : prev, []);
        },
    },
    mounted() {
        this.init()
    }
}
</script>

<style scoped lang="scss">
.city__h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: #000000;
    margin-top: 20px;
    margin-bottom: 20px;
}

.city__card {
    background: #fff;
    min-width: 100%;
    padding: 20px 22px;
    margin-bottom: 15px;

    .city__first-row {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        height: 50px;

        .city__name {
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 22px;
            color: var(--main_color-base);
        }

        .city__qty-dev,
        .city__qty-residential-complexes {
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 22px;
            color: var(--main_color-base);
        }
    }

    .city__second-row {
        display: flex;
        align-items: flex-start;

        .city__img {
            width: 273px;
            height: 219px;
            margin-right: 20px;

            img {
                width: 273px;
                height: 219px;
                object-fit: cover;

                @media all and (max-width: 900px) {
                    width: 102px;
                    height: 141px;
                }
            }

            @media all and (max-width: 900px) {
                width: 102px;
                height: 141px;
                overflow: hidden;

            }
        }

        .city__map {
            flex-grow: 1;
        }
    }
}
</style>
